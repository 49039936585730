import url from "../../server";
import '../../css/projects.css'
import { Link } from "react-router-dom";

function Project(props)
{
    let image_url = props.project.Images[0].url;
    
    if(props.project.Images[0].formats.medium)
        image_url = props.project.Images[0].formats.medium.url

    return (
    <div className="project_tile">
    <Link to={{
        pathname: "/projects/"+props.project.id,
        project: props.project,
        }}>
    {/* <Link to={"/projects/"+props.project.id}> */}
    <div 
        className="project_tile_display"
        style={{
            '--data-picture': 'url('+url+image_url+')'
        }}>
        <div className="project_overlay">
            <h3 className="project_overlay_text">
                {props.project.Titre}
            </h3>
        </div>
        
    </div>
    </Link>
    </div>);
}

export default Project;