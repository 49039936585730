import { Component } from "react";
import url from "../../server";
import "../../css/experience/experience.css"

class Experiences extends Component{

    state = {
        isFetching: false,
        experiences: [] 
    }

    constructor(props){
        super(props);

        this.fetchExperiences = this.fetchExperiences.bind(this);
    }

    fetchExperiences() {
        this.setState({ isFetching: true});
        fetch(url+"/experiences")
            .then(response => response.json())
            .then(result => {
                this.setState({
                    experiences: result.sort( function s(a,b) {
                        return b.fin < a.fin ? -1
                        : b.fin > a.fin ? 1
                        : 0
                    }), 
                    isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({experiences: [], isFetching: false});
            });
    }

    componentDidMount() {
        this.fetchExperiences();
        // this.timer = setInterval(() => this.fetchProjects(), 5000);
    }

    componentWillUnmount() {
        // clearInterval(this.timer);
        // this.timer = null;
    }

    render()
        {
        var content = []
        this.state.experiences.forEach( experience => {
            content.push(
                <tr className="experience_container">
                    <td key={"experience_title"+experience.id} className="experience_year" >{experience.fin.split("-")[0]}</td>
                    <td key={"experience_year"+experience.id} className="experience_content" dangerouslySetInnerHTML={{__html: experience.contenu}}/>
                </tr>
            );
        });

        return(
            <table className="experiences_container">
                <tbody>
                {content}
                </tbody>
            </table>
        );
    }

}
export default Experiences;