
import TitleBar from "../components/TitleBar";
import Projects from "../components/Projects/Projects";
import ContactFooter from "../components/Contact/contact_footer";


import {
    Routes,
    Route
  } from "react-router-dom";

function Home(props) {
    return (
        <div>
            <Routes>
                <Route path="/" element={<TitleBar/>}/>
            </Routes>
            <Projects/>
            <ContactFooter className="hidden_mobile"/>
            <a href="/legal" style={{float: "right", padding: "0.5em", color: "black"}}>Mentions légales</a>
        </div>
    );
}

export default Home;