import { Component } from "react";
import url from "../../server";
import "../../css/contact/contact_footer.css"

class ContactFooter extends Component{

    state = {
        isFetching: false,
        contact: null 
    }

    constructor(props){
        super(props);

        this.fetchContact = this.fetchContact.bind(this);
    }

    fetchContact() {
        this.setState({ isFetching: true});
        let projects = "";
        fetch(url+"/contacts")
            .then(response => response.json())
            .then(result => {
                this.setState({
                    contact: result[0], 
                    isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({contact: null, isFetching: false});
            });
    }

    componentDidMount() {
        this.fetchContact();
        // this.timer = setInterval(() => this.fetchProjects(), 5000);
    }

    componentWillUnmount() {
        // clearInterval(this.timer);
        // this.timer = null;
    }

    render()
        {
        var content = [] 
        if( null != this.state.contact)
        {
            content = [
            <div key="footer_name" className="contact_footer_entry hidden_mobile">{this.state.contact.nom}</div>,
            <div key="footer_sep1" className="contact_footer_entry hidden_mobile">-</div>,
            <div key="footer_phone" className="contact_footer_entry hidden_mobile">{this.state.contact.telephone}</div>,
            <div key="footer_sep2" className="contact_footer_entry hidden_mobile">-</div>,
            <div key="footer_mail" className="contact_footer_entry hidden_mobile">{this.state.contact.mail}</div>,
            <div key="footer_sep3" className="contact_footer_entry hidden_mobile">-</div>,
            <div key="footer_loc" className="contact_footer_entry hidden_mobile">{this.state.contact.localisation}</div>
            ];
        }
        return(
            <div className="contact_footer">
                {content}
            </div>
        );
    }

}
export default ContactFooter;