

let url = "";
switch(process.env.NODE_ENV) {
  case 'production':
    url = 'https://leadammann.fr/garage/';
    break;
  case 'development':
  default:
    url = 'http://localhost:1337';
}

export default url