import { render } from '@testing-library/react';
import React from 'react';
import {Link, useLocation} from 'react-router-dom'
import url from '../../server';
import '../../css/project_page.css'
import ButtonBack from '../ButtonBack';

export default function ProjectPage(props)
{
    const [project, onGotProject] = React.useState(null);

    let project_id = useLocation().pathname.split("/").pop();
    if(null == project)
    {
        fetch(url+"/projects"+"/"+project_id)
            .then(response => response.json())
            .then(result => {
                onGotProject(result);
            })
            .catch(e => {
                console.log(e);
            });
    }

    var project_title_class = "project_page_title"
    var project_description_class = "project_page_description"
    var project_name = " "
    var project_description = " "
    var project_images = ""
    if(null != project){
        project_name = project.Titre
        project_description = project.Description
        project_images = []
        project.Images.forEach(image => {
            console.log(image);
            var image_url = image.url;
            if( image.formats.medium )
                image_url = image.formats.medium.url
            project_images.push(
                <img 
                    src={url+image.url}
                    key={"imgproj"+image.id} 
                    className="project_page_picture"
                    style={{
                        '--data-picture': 'url('+url+image_url+')'
                    }}/>)
        });
        if(null != project.youtube && project.youtube.length > 0)
        project.youtube.split("\n").forEach(url =>{
            project_images.push(
                <iframe 
                    className="project_page_picture"
                    height="500"
                    src={url} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen></iframe>
            );
        });
    }
    else{
        project_description_class += " empty"
        project_title_class += " empty"
    }
    return <div className="project_page">
        <div className="project_page_text">
            <div className={project_title_class}>
                {project_name}
            </div>
            <div className={project_description_class}>
                {project_description}
            </div>
            <div className="project_page_back hidden-mobile">
                <ButtonBack/>
            </div>
        </div>
        <div className="project_page_pictures">
            {project_images}
        </div>
        <div className="project_page_back hidden-desktop">
                <ButtonBack/>
            </div>
        
    </div>
}
