import {
    Link
  } from "react-router-dom";

function ButtonBack(props){
    
    return(
        <div 
        className="button_back"
        style={{
            '--data-move': 5+'px'
        }}>
            <Link to="/">
                <div 
                className="button_back_element"
                style={{
                    '--data-move': 1+'px'
                }}>&lt; RETOUR</div>
            </Link>
        </div>
    )
}

export default ButtonBack;
