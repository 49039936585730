import React from 'react'
import ButtonBack from '../components/ButtonBack'
import TitleBar from '../components/TitleBar'

export default function Legal(props){
    return (
        <React.Fragment>
        <TitleBar/>
        <div className="fc aic">
            <div className="fr fw">
                <div className="mw19em fc aifs p1em">
                    <h2 className='menu_item'>Léa Dammann</h2>
                    <div>Siret n°90132008500011</div>
                </div>
                <div className="mw19em fc aifs p1em">
                    <h2 className='menu_item'>Contact</h2>
                    <div>+33 6 49 14 88 73 | dmn3.lea@gmail.com</div>
                </div>
                <div className="mw19em fc aifs p1em">
                    <h2 className='menu_item'>Développé par</h2>
                    <div>Elias Sebbar - <a href="https://eliassebbar.info">https://eliassebbar.info</a></div>
                </div>
                <div className="mw19em fc aifs p1em">
                    <h2 className='menu_item'>Hébergé chez</h2>
                    <div>OVH - <a href="https://ovh.com">https://ovh.com</a> Siège social : 2 rue Kellermann - 59100 Roubaix, France Tél. +33 (0)8 99 70 17 61 </div>
                </div>
            <div className="p1em" style={{flexBasis: "100%", paddingTop: "5em"}}>
            <ButtonBack/>
            </div>
            </div>
        </div>
        </React.Fragment>
    )
}