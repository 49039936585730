import {Link} from "react-router-dom";


function NavControlAbout(){

    return(
        <h2 className="nav_control">
            <Link to="/about">CONTACT / <span className="no_long_spaces">A PROPOS</span></Link>
        </h2>
    )
}

export default NavControlAbout;