import { Component } from "react";
import url from "../../server";
import "../../css/contact/contact.css"

class Contact extends Component{

    state = {
        isFetching: false,
        contact: null 
    }

    constructor(props){
        super(props);

        this.fetchContact = this.fetchContact.bind(this);
    }

    fetchContact() {
        this.setState({ isFetching: true});
        let projects = "";
        fetch(url+"/contacts")
            .then(response => response.json())
            .then(result => {
                this.setState({
                    contact: result[0], 
                    isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({contact: null, isFetching: false});
            });
    }

    componentDidMount() {
        this.fetchContact();
    }

    componentWillUnmount() {
    }

    render()
        {
        var content = [] 
        if( null != this.state.contact)
        {
            content = [
            <div key="contact_name" className="contact_entry contact_name">{this.state.contact.nom}</div>,
            <div key="contact_title" className="contact_entry">DESIGNER PLURIDISCIPLINAIRE</div>,
            <div key="contact_phone" className="contact_entry">{this.state.contact.telephone}</div>,
            <div key="contact_mail" className="contact_entry">{this.state.contact.mail}</div>,
            <div key="contact_loc" className="contact_entry">{this.state.contact.localisation}</div>
            ];
        }
        return(
            <div className="contact">
                {content}
            </div>
        );
    }

}
export default Contact;