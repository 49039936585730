
import {Link} from "react-router-dom";
import Title from "./Title";
import '../css/title_bar/title_bar.css';
import '../css/title_bar/title_bar_mobile.css';
import NavControlAbout from "./NavControlAbout";

function TitleBar(props) {
    return (
        <div className="title_bar">
            <Title/>
            <div className="title_bar_half title_bar_controls">
                <a href="#projects" ><h2 className="nav_control">DESIGN /  RECHERCHE</h2></a>
                <NavControlAbout/>
            </div>
        </div> 

    );
}

export default TitleBar;