import { render } from "@testing-library/react";
import { Component } from "react";
import url from "../../server";
import Project from "./Project";

class Projects extends Component{

    state = {
        isFetching: false,
        projects: [] 
    }

    constructor(props){
        super(props);

        this.fetchProjects = this.fetchProjects.bind(this);
    }

    fetchProjects() {
        this.setState({ isFetching: true});
        let projects = ""
        fetch(url+"/projects")
            .then(response => response.json())
            .then(result => {
                console.log(result);
                this.setState({
                    projects: result.sort(function s(a,b)
                        {
                        return b.Ordre < a.Ordre ? 1 
                            : b.Ordre > a.Ordre ? -1
                            : 0
                        }), 
                    isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({projects: [], isFetching: false});
            });
       }

    componentDidMount() {
        this.fetchProjects();
        // this.timer = setInterval(() => this.fetchProjects(), 5000);
    }

    componentWillUnmount() {
        // clearInterval(this.timer);
        // this.timer = null;
    }

    render()
        {
        var content = [] 
        if(!this.state.isFetching && this.state.projects.length > 0)
        {
            this.state.projects.forEach(project => {
                content.push(<Project key={project.id} project={project}/>);
            });
        }
        return(
            <div id="projects" className="projects_list">
                {content}
            </div>
        );
    }
}
export default Projects;