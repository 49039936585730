import React from 'react';
import ReactDOM from 'react-dom'
import './App.css';
import './css/util.css'
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate
} from "react-router-dom";
import url from './server';
import Home from './pages/home';
import About from './pages/about';
import Project from './components/Projects/Project';
import ProjectPage from './components/Projects/ProjectPage';
import Legal from './pages/legal';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/about" element={<About/>}/>
          <Route path="/project" element={<Project/>}/>
          <Route path="/projects/:projId" element={<ProjectPage/>}/>
          <Route path="/" element={<Home/>}/>
          <Route path="/legal" element={<Legal/>}/>
          <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);

export default App;
