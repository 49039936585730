import {Link} from 'react-router-dom'
import TitleBar from '../components/TitleBar';
import ButtonBack from '../components/ButtonBack';
import Experiences from '../components/Experiences/Experiences';
import NavControlAbout from '../components/NavControlAbout';
import '../css/about.css';
import Contact from '../components/Contact/Contact';

function About(props) {
   
    return (
    <div>
        <div className="about_title">
        <NavControlAbout/>
        </div>
        <div className="about_content">
            <div>
                <Contact/>
            </div>
            <div className="about_experiences">
                <Experiences/>
            </div>
            
        </div>
        
        <div className="about_back">
            <ButtonBack/>
        </div>
        
    </div>);
}

export default About