import React, { useState } from 'react';
import {
    Link
  } from "react-router-dom";
import '../css/title_bar/title.css'

function Title(props) {
    const [position, newPosition] = useState({clientY : -2});
    const [firstPosition, positionBegin] = useState({clientY : 0});

    let max_translate_x = 20;
    let max_translate_y = 25;
    let translate_x = firstPosition !==  0 ? position.clientX - firstPosition.clientX : 0
    let translate_y = firstPosition !==  0 ? position.clientY - firstPosition.clientY : 0
    
    if(null != position.changedTouches){
        translate_x = firstPosition !==  0 ? (position.changedTouches[0].clientX - firstPosition.changedTouches[0].clientX) : 0
        translate_y = firstPosition !==  0 ? (position.changedTouches[0].clientY - firstPosition.changedTouches[0].clientY) : 0     
    }

    if(translate_y > 0)
    {
        translate_y = max_translate_y
    }
    else
        if(translate_y < 0)
            translate_y = -max_translate_y

    return(
            <div 
            onMouseEnter={(event) => positionBegin(event)}
            onTouchStart={(event) => positionBegin(event)}
            onMouseMove={(event) => newPosition(event)}
            onTouchMove={(event) => newPosition(event)}
            
            // onMouseLeave={(event) => {positionBegin(0)}}
            // onTouchEnd={(event) => {positionBegin(0)}}

            className="title_bar_title"
            style={{
                '--translate-x' : 0+"%",
                '--translate-y' : (translate_y > 0 ? -0.75 : -0.75)*translate_y+"%",
            }}
            >
                <Link to="/" >
                    <h1 
                        style={{
                            '--translate-x' : 0+"%",
                            '--translate-y' : translate_y*0.75+"%",
                            '--color':'#c0bebf',
                            '--index': 1
                        }}
                        className="title_bar_entry movable">LEA DAMMANN </h1>
                    <div 
                        style={{
                            '--translate-x' : (0*0.5)+"%",
                            '--translate-y' : (translate_y*0.25)+"%",
                            '--color':'#747273',
                            '--index': 2
                        }}
                        className="title_bar_entry movable">LEA DAMMANN</div>
                    <div
                        style={{
                            '--color':'#231f20',
                            '--index': 3
                        }}
                        className="title_bar_entry positioned">LEA DAMMANN</div>
                </Link>
            </div>
    );
}

export default Title;